import { GridSelectionModel } from '@mui/x-data-grid'
import { BonusGraph, PaymetsGraph } from 'app/dashboard/Types/DashboardTypes'
import { DatatoSend } from 'app/profile/myCompany/paymentMethod/addMethodDialog/useAddCard'
import { RequestWithdraw } from 'app/wallet/hooks/useWithdraw'
import { BonusPayments } from 'app/wallet/types'
import axios from 'axios'
import { config } from 'config'
import type {
  Params,
  PaymentsParams,
  PublicJobBoardParams,
  ReferralsParams
} from 'types/api'
import type { SignUpResponse, User } from 'types/auth'
import { ListPayments } from 'types/bonusPayments'
import {
  ListTemplates,
  BonusTemplateDetails,
  NewBonusTemplate,
  UpdateBonusTemplateParams,
  BonusConfig,
  StatusBonusTemplateBulkActions,
  StatusPaymentTemplateBulkActions
} from 'types/bonusTemplate'
import type {
  Company,
  CompanyDetail,
  CreateCompanyAddressInfo,
  SentCompanyData,
  UpdateCompaniesStatusData,
  UpdateCompanyInfo
} from 'types/companies'
import { Integration } from 'types/integrations'
import type {
  JobBoard,
  JobDetail,
  JobImport,
  JobApplication,
  UpateJobStatusData,
  UpdateJobVisibility,
  Counter
} from 'types/jobs'
import { NotificationData } from 'types/notification'
import { PositionProps } from 'types/positions'
import type {
  BeegoppEntity,
  CompanyConfigDetail,
  CompanyEntities,
  ConfigurationTosend,
  EntityName,
  ImageUpdate,
  PaymentMethod,
  PaymentMethodResponse,
  TangoResponse,
  Transaction,
  UserChangePassword,
  UserInformation
} from 'types/profile'
import { Referrals, ReferralDetail, ReferralByEmailData } from 'types/referrals'
import { getCompanyId, pickBy, removeEmptyKeys } from 'utils'
import { UpdateEmployeeTemplateParams } from '../app/employees/EmployeeDetail/Types/EmployeeDetail'
import {
  ReferralByEmployeeBoard,
  CountsByEmployeeBoard
} from '../app/employees/EmployeeDetail/Types/ReferralByEmployee'
import { ReadyToPay } from '../app/employees/EmployeeDetail/Types/ReferralByEmployee'
import {
  RoleEmployeesTemplateBulkActions,
  SendEmployeeRequest,
  StatusEmployeesTemplateBulkActions
} from '../app/employees/Types/Employees'
import { EmployeeBoard } from '../app/employees/Types/Employees'
import { BonusByCompany } from '../types/api'
import { BonusPaymentDetails } from '../types/bonusPayments'

import {
  listEmployees,
  listReferrals,
  listReferralsByEmployee,
  login,
  getReferral,
  listJobs,
  signUp,
  codeSms,
  baseBonus,
  codeEmail,
  resetPassword,
  configuration,
  listCompanies,
  jobDetail,
  bulkUpdateBonusTemplates,
  bonusTemplatesByCompany,
  importJobs,
  companyDetail,
  jobsByCompany,
  changePassword,
  bonusPayments,
  getUser,
  updateUser,
  createEmployees,
  updateEmployee,
  referralByJobId,
  companyEntities,
  countByEmployeeId,
  deleteBonusConfig,
  updateConfiguration,
  comapnyConfigDetail,
  publicJobsByCompany,
  publicJobDetail,
  newJobApplication,
  referralByEmailSms,
  countByUserId,
  paymentById,
  jobStatus,
  loginAsCompany,
  readyToPay,
  requestbyWithdraw,
  jobVisibility,
  companyProfile,
  beegoppEntities,
  toggleConfig,
  getBonusPaymentsWallet,
  bulkUpdatePaymentStatus,
  dashReferral,
  dashOverview,
  dashPayments,
  dashJobs,
  dashBonus,
  dashReferralFunnel,
  dashOverviewE,
  dashReferralE,
  dashPaymentsE,
  dashJobsE,
  dashReferralFunnelE,
  listCompaniesByFilterJobs,
  jobsTypes,
  jobCounters,
  sendNotificationToken,
  userNotifications,
  jobCounterViews,
  viewedUserNotification,
  reportPayments,
  register,
  addPaymentMethod,
  tangoCompany,
  resendCompaniesInvite,
  resendEmployeeInvite,
  rechargeCreditCard,
  paymentMethods,
  activePaymentMethod,
  transactions,
  unRegisterCard,
  getFunds,
  jobDelete,
  rechargeWireTransfer,
  deleteBonusTemplates,
  getPositions,
  clearPositions,
  buyPositions,
  comapnyIntegrations
} from './service'

const API_URL = config.beegoppApi
const API_PREFIX = config.beegoppPrefix
const BASE_URL = `${API_URL}/${API_PREFIX}`

export const LoginAsCompany = async (params: { owner_id: number }) => {
  const { data } = await axios.post<{ data: User }>(
    `${BASE_URL}${loginAsCompany}`,
    params
  )
  return data.data
}

export const Login = async ({
  email,
  password
}: {
  email: string
  password: string
}) => {
  const { data } = await axios.post<{ data: User }>(`${BASE_URL}${login}`, {
    email,
    password
  })
  return data
}

export const UserRegisters = async (dataToSend: {
  phone_area_code: ''
  first_name: ''
  last_name: ''
  company: ''
  phone: ''
  email: ''
}) => {
  const { data } = await axios.post<{ data: User }>(
    `${BASE_URL}${register}`,
    dataToSend
  )
  return data
}

export const ValidateSms = async (dataToSend: {
  email: string
  nip_phone: string
}) => {
  const { data } = await axios.post<{ data: SignUpResponse }>(
    `${BASE_URL}${codeSms}`,
    dataToSend
  )
  return data
}

export const ReportPayments = async (params: {
  init_date: Date
  end_date: Date
}) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get(`${BASE_URL}${reportPayments(companyId)}`, {
    params,
    responseType: 'blob'
  })
  return data
}

export const ValidateEmail = async (dataToSend: {
  email: string
  nip_email: string
}) => {
  const { data } = await axios.post<{ data: SignUpResponse }>(
    `${BASE_URL}${codeEmail}`,
    dataToSend
  )
  return data
}

export const ResetPassword = async (dataToSend: { email: string }) => {
  const { data } = await axios.put(`${BASE_URL}${resetPassword}`, dataToSend)
  return data
}

export const AssignPassword = async (dataToSend: {
  token: string
  password: string
}) => {
  const { data } = await axios.put<{ data: SignUpResponse }>(
    `${BASE_URL}${resetPassword}`,
    dataToSend
  )
  return data
}

export const GetEmployees = async (params?: Params) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: EmployeeBoard }>(
    `${BASE_URL}${listEmployees(companyId)}`,
    { params: pickBy(params || {}) }
  )
  return data
}

export const GetPaymentsReady = async (employeeId: string | number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: ReadyToPay }>(
    `${BASE_URL}${readyToPay(employeeId, companyId)}`
  )
  return data
}

export const GetReferralGraph = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const data = await axios.get(
    `${BASE_URL}${dashReferral(employeeId, companyId, initialDate, endDate)}`
  )
  return data
}
export const GetReferralGraphE = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const data = await axios.get(
    `${BASE_URL}${dashReferralE(employeeId, companyId, initialDate, endDate)}`
  )
  return data
}

export const GetJobsGraph = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const data = await axios.get(
    `${BASE_URL}${dashJobs(employeeId, companyId, initialDate, endDate)}`
  )
  return data
}

export const GetJobsGraphE = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const data = await axios.get(
    `${BASE_URL}${dashJobsE(employeeId, companyId, initialDate, endDate)}`
  )
  return data
}

export const GetPaymetsGraph = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: PaymetsGraph }>(
    `${BASE_URL}${dashPayments(employeeId, companyId, initialDate, endDate)}`
  )
  return data
}
export const GetPaymetsGraphE = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: PaymetsGraph }>(
    `${BASE_URL}${dashPaymentsE(employeeId, companyId, initialDate, endDate)}`
  )
  return data
}

export const GetBonusGraph = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: BonusGraph }>(
    `${BASE_URL}${dashBonus(employeeId, companyId, initialDate, endDate)}`
  )
  return data
}
export const GetReferralFunnelGraph = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const data = await axios.get(
    `${BASE_URL}${dashReferralFunnel(
      employeeId,
      companyId,
      initialDate,
      endDate
    )}`
  )
  return data
}
export const GetReferralFunnelGraphE = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const data = await axios.get(
    `${BASE_URL}${dashReferralFunnelE(
      employeeId,
      companyId,
      initialDate,
      endDate
    )}`
  )
  return data
}
export const GetOverviewData = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const data = await axios.get(
    `${BASE_URL}${dashOverview(employeeId, companyId, initialDate, endDate)}`
  )
  return data
}
export const GetOverviewDataE = async (
  employeeId: string | number,
  initialDate: Date | string,
  endDate: Date | string
) => {
  const { companyId } = getCompanyId()
  const data = await axios.get(
    `${BASE_URL}${dashOverviewE(employeeId, companyId, initialDate, endDate)}`
  )
  return data
}

export const GetEmployeesById = async (id: string | number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get(
    `${BASE_URL}${listEmployees(companyId)}/${id}`
  )
  return data.data
}

export const GetReferalByEmployeeId = async (id: number, params?: Params) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: ReferralByEmployeeBoard }>(
    `${BASE_URL}${listReferralsByEmployee(companyId, id)}`,
    { params: pickBy(params || {}) }
  )
  return data
}

export const CreateEmployeeRequest = async (
  companyId: number,
  dataToSend: SendEmployeeRequest
) => {
  const { data } = await axios.post(
    `${BASE_URL}${createEmployees(companyId)}`,
    dataToSend
  )
  return data
}

export const DeleteEmployees = async (dataToSend: {
  employees: GridSelectionModel
}) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.delete(
    `${BASE_URL}${createEmployees(companyId)}`,
    {
      data: dataToSend
    }
  )
  return data
}

export const ResendEmployeeInvite = async (dataTosend: {
  employees: GridSelectionModel
}) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post<any>(
    `${BASE_URL}${resendEmployeeInvite(companyId)}`,
    dataTosend
  )
  return data
}

export const BulkUpdateEmployeeTemplates = async (
  dataToSend:
    | StatusEmployeesTemplateBulkActions
    | RoleEmployeesTemplateBulkActions
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put(
    `${BASE_URL}${listEmployees(companyId)}`,
    dataToSend
  )
  return data
}

export const UpdateEmployeeTemplate = async (
  employeeId: number,
  dataToSend: { employee: Partial<UpdateEmployeeTemplateParams> }
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put<{ data: any }>(
    `${BASE_URL}${updateEmployee(employeeId, companyId)}`,
    dataToSend
  )
  return data
}

export const GetCountsByEmployeeId = async (employeeId: string | number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: CountsByEmployeeBoard }>(
    `${BASE_URL}${countByEmployeeId(employeeId, companyId)}`
  )
  return data
}

export const GetCountsByUserId = async (employeeId: string | number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: CountsByEmployeeBoard }>(
    `${BASE_URL}${countByUserId(employeeId, companyId)}`
  )
  return data
}

export const GetCompanies = async (params?: Params & { search: string }) => {
  const { data } = await axios.get<{ data: Company }>(
    `${BASE_URL}${listCompanies}`,
    {
      params: pickBy(params || {})
    }
  )
  return data
}

export const GetCompaniesByFilterJobs = async (params: {
  status: Array<'active' | 'published'>
}) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: Array<string> }>(
    `${BASE_URL}${listCompaniesByFilterJobs(companyId)}`,
    {
      params: pickBy(params || {})
    }
  )
  return data.data
}

export const GetShowCompany = async (companyId: number) => {
  const { data } = await axios.get<{ data: CompanyDetail }>(
    `${BASE_URL}${companyDetail(companyId)}`
  )
  return data
}

export const GetProfileCompanyDetail = async (companyId: number) => {
  const { data } = await axios.get<{ data: CompanyDetail }>(
    `${BASE_URL}${companyProfile(companyId)}`
  )
  return data
}

export const UpdateCompany = async (
  companyId: number,
  dataToSend: UpdateCompanyInfo
) => {
  const { data } = await axios.put<{ data: any }>(
    `${BASE_URL}${companyDetail(companyId)}`,
    dataToSend
  )
  return data
}

export const UpdateProfileCompany = async (
  companyId: number,
  dataToSend: UpdateCompanyInfo
) => {
  const { data } = await axios.put<{ data: any }>(
    `${BASE_URL}${companyProfile(companyId)}`,
    dataToSend
  )
  return data
}

export const UpdateCompaniesStatus = async (
  dataToSend: UpdateCompaniesStatusData
) => {
  const { data } = await axios.put<{ data: any }>(
    `${BASE_URL}${listCompanies}`,
    dataToSend
  )
  return data
}

export const CreateCompanyAddress = async (
  companyId: number,
  dataToSend: CreateCompanyAddressInfo
) => {
  const { data } = await axios.put<{ data: CompanyDetail }>(
    `${BASE_URL}${companyProfile(companyId)}`,
    dataToSend
  )
  return data
}

export const GetReferrals = async (
  companyId: number,
  params?: ReferralsParams
) => {
  const { data } = await axios.get<{ data: Referrals }>(
    `${BASE_URL}${listReferrals(companyId)}`,
    {
      params: pickBy(params || {})
    }
  )
  return data
}
export const GetBonusPayments = async (params?: PaymentsParams) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: ListPayments }>(
    `${BASE_URL}${bonusPayments(companyId)}`,
    {
      params: pickBy(params || {})
    }
  )
  return data
}

export const GetBonusTemplates = async (params?: Params) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: ListTemplates }>(
    `${BASE_URL}${baseBonus(companyId)}`,
    {
      params: pickBy(params || {})
    }
  )
  return data
}

export const DeleteBonusTemplates = async (dataTosend: {
  ids: GridSelectionModel
}) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.delete<any>(
    `${BASE_URL}${deleteBonusTemplates(companyId)}`,
    {
      data: dataTosend
    }
  )
  return data
}

export const GetBonusTemplatesByCompany = async (
  companyId: number,
  params?: BonusByCompany
) => {
  const { data } = await axios.get<{ data: ListTemplates }>(
    `${BASE_URL}${bonusTemplatesByCompany(companyId)}`,
    {
      params: pickBy(params || {})
    }
  )
  return data
}

export const SignUp = async (dataToSend: {
  email: string
  phone: string
  code: string
}) => {
  const { data } = await axios.post<{ data: SignUpResponse }>(
    `${BASE_URL}${signUp}`,
    dataToSend
  )
  return data
}

export const AddBonusTemplate = async (dataToSend?: NewBonusTemplate) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post<{ data: unknown }>(
    `${BASE_URL}${baseBonus(companyId)}`,
    dataToSend
  )
  return data
}

export const CreateJobApplication = async (
  dataToSend: JobApplication,
  companyId: number,
  jobId: number
) => {
  const { data } = await axios.post<{ data: unknown }>(
    `${BASE_URL}${newJobApplication(companyId, jobId)}`,
    dataToSend
  )
  return data
}

export const UpdateBonusTemplate = async (
  dataToSend: UpdateBonusTemplateParams,
  id: number
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put(
    `${BASE_URL}${baseBonus(companyId)}/${id}`,
    dataToSend
  )
  return data
}

export const BulkUpdateBonusTemplates = async (
  dataToSend: StatusBonusTemplateBulkActions
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put(
    `${BASE_URL}${bulkUpdateBonusTemplates(companyId)}`,
    dataToSend
  )
  return data
}
export const BulkUpdateStatusPaymentTemplates = async (
  dataToSend: StatusPaymentTemplateBulkActions
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post(
    `${BASE_URL}${bulkUpdatePaymentStatus(companyId)}`,
    dataToSend
  )
  return data
}

export const UpdateBonusConfig = async (
  dataToSend: BonusConfig,
  bonusId: number,
  configId: number
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put(
    `${BASE_URL}${baseBonus(companyId)}/${bonusId}/configs/${configId}`,
    dataToSend
  )
  return data
}

export const DeleteBonusConfig = async (bonusId: number, configId: number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.delete(
    `${BASE_URL}${deleteBonusConfig({ bonusId, configId, companyId })}`
  )
  return data
}

export const AddBonusConfig = async (
  dataToSend: BonusConfig,
  bonusId: number
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post(
    `${BASE_URL}${baseBonus(companyId)}/${bonusId}/configs`,
    dataToSend
  )
  return data
}

export const getBonusTemplateById = async (id: number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: BonusTemplateDetails }>(
    `${BASE_URL}${baseBonus(companyId)}/${id}`
  )
  return data.data
}
export const getBonusPaymentById = async (id: number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: BonusPaymentDetails }>(
    `${BASE_URL}${paymentById(id, companyId)}`
  )
  return data.data
}

export const GetReferralById = async (id: number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: ReferralDetail }>(
    `${BASE_URL}${getReferral(id, companyId)}`
  )
  return data
}

export const GetReferralByJobId = async (jobId: number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: Referrals }>(
    `${BASE_URL}${referralByJobId(jobId, companyId)}`
  )
  return data.data
}

export const JobCounterViews = async (jobId: number, companyId: number) => {
  const { data } = await axios.put(
    `${BASE_URL}${jobCounterViews(companyId, jobId)}`
  )
  return data
}

export const GetJobs = async (
  params?: Params & {
    has_bonus?: boolean
    status?: Array<string>
    type?: Array<string> | string
    company_name?: string
  }
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: JobBoard }>(
    `${BASE_URL}${listJobs(companyId)}`,
    {
      params: removeEmptyKeys(params || {})
    }
  )
  return data.data
}

export const GetJobsTypes = async () => {
  const { companyId } = getCompanyId()
  const params = { status: ['active', 'published'] }
  const { data } = await axios.get<{ data: Array<string> }>(
    `${BASE_URL}${jobsTypes(companyId)}`,
    { params }
  )
  return data.data
}

export const GetJobsByCompany = async (
  companyId: number,
  params: { start: number; count: number; id: string }
) => {
  const { data } = await axios.get<{ data: JobBoard }>(
    `${BASE_URL}${jobsByCompany(companyId)}`,
    {
      params: pickBy(params || {})
    }
  )
  return data.data
}

export const GetPublicJobsByCompany = async (
  companyId: number,
  params?: PublicJobBoardParams
) => {
  const { data } = await axios.get<{ data: JobBoard }>(
    `${BASE_URL}${publicJobsByCompany(companyId)}`,
    {
      params: pickBy(params || {})
    }
  )
  return data.data
}

export const UpdateJobsStatus = async (dataTosend: UpateJobStatusData) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put<{
    data: {
      updated: number
    }
  }>(`${BASE_URL}${jobStatus(companyId)}`, dataTosend)
  return data.data
}

export const DeleteJobs = async (dataTosend: UpateJobStatusData) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put<any>(
    `${BASE_URL}${jobDelete(companyId)}`,
    dataTosend
  )
  return data.data
}

export const UpdateJobsVisibility = async (
  dataTosend: UpdateJobVisibility,
  jobId: number
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put<any>(
    `${BASE_URL}${jobVisibility(jobId, companyId)}`,
    dataTosend
  )
  return data.data
}
export const BuyJobsPositions = async (
  dataTosend: { positions: number },
  jobId: number
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post<any>(
    `${BASE_URL}${buyPositions(jobId, companyId)}`,
    dataTosend
  )
  return data.data
}

export const GetCompanyEntities = async (
  companyId: number,
  params: { entity: EntityName }
) => {
  const { data } = await axios.get<{ data: Array<CompanyEntities> }>(
    `${BASE_URL}${companyEntities(companyId)}`,
    {
      params: pickBy(params || {})
    }
  )
  return data.data
}

export const GetEntities = async (params: { entity: EntityName }) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: BeegoppEntity }>(
    `${BASE_URL}${beegoppEntities(companyId)}`,
    {
      params: pickBy(params || {})
    }
  )
  return data.data
}

export const GetPublicJobDetails = async (companyId: number, jobId: number) => {
  const { data } = await axios.get<{ data: JobDetail }>(
    `${BASE_URL}${publicJobDetail(companyId, jobId)}`
  )
  return data
}

export const GetJobDetails = async (jobId: number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: JobDetail }>(
    `${BASE_URL}${jobDetail(jobId, companyId)}`
  )
  return data
}

export const GetJobCounters = async (jobId: number) => {
  const { data } = await axios.get<{ data: Counter }>(
    `${BASE_URL}${jobCounters(jobId)}`
  )
  return data.data
}

export const ReferralByEmailSms = async (dataTosend: ReferralByEmailData) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post<{ data: any }>(
    `${BASE_URL}${referralByEmailSms(companyId)}`,
    dataTosend
  )
  return data.data
}

export const RequestSendWithdraw = async (
  userId: number,
  dataTosend: RequestWithdraw
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put<{ data: any }>(
    `${BASE_URL}${requestbyWithdraw(userId, companyId)}`,
    dataTosend
  )
  return data.data
}
export const GetBonusPaymentsWallet = async (
  params?: Params,
  id?: number | string | undefined
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: BonusPayments }>(
    `${BASE_URL}${getBonusPaymentsWallet(id, companyId)}`,
    { params: pickBy(params || {}) }
  )
  return data
}

export const OnToggleConfig = async (
  dataTosend: { config: { enable: 'true' | 'false' } },
  configId: number
) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put<any>(
    `${BASE_URL}${toggleConfig(companyId, configId)}`,
    dataTosend
  )
  return data
}

export const AddCompanyConfig = async (
  companyId: number,
  dataTosend: ConfigurationTosend
) => {
  const { data } = await axios.post<any>(
    `${BASE_URL}${configuration(companyId)}`,
    dataTosend
  )
  return data.data
}

export const UpdateCompanyConfig = async ({
  companyId,
  configId,
  dataTosend
}: {
  companyId: number
  configId: number
  dataTosend: ConfigurationTosend
}) => {
  const { data } = await axios.put<any>(
    `${BASE_URL}${updateConfiguration(companyId, configId)}`,
    dataTosend
  )
  return data
}

export const GetCompanyConfigDetail = async (
  companyId: number,
  configId: number
) => {
  const { data } = await axios.get<CompanyConfigDetail>(
    `${BASE_URL}${comapnyConfigDetail(companyId, configId)}`
  )
  return data
}

export const GetCompanyIntegrations = async () => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: Array<Integration> }>(
    `${BASE_URL}${comapnyIntegrations}`,
    {
      params: { company_id: companyId }
    }
  )
  return data.data
}

export const UpdateCompanyImage = async (
  companyId: number,
  dataTosend: { company: Partial<ImageUpdate> }
) => {
  const { data } = await axios.put<any>(
    `${BASE_URL}${companyProfile(companyId)}`,
    dataTosend
  )
  return data
}

export const AddImportJobs = async (
  companyId: number,
  dataTosend: JobImport
) => {
  const { data } = await axios.post<any>(
    `${BASE_URL}${importJobs(companyId)}`,
    dataTosend
  )
  return data
}

export const SentCompanyInvite = async (dataTosend: {
  companies: Array<SentCompanyData>
}) => {
  const { data } = await axios.post<any>(
    `${BASE_URL}${listCompanies}`,
    dataTosend
  )
  return data
}

export const DeleteCompanies = async (dataTosend: {
  companies: GridSelectionModel
}) => {
  const { data } = await axios.delete<any>(`${BASE_URL}${listCompanies}`, {
    data: dataTosend
  })
  return data
}

export const ResendCompanyInvite = async (dataTosend: {
  companies: GridSelectionModel
}) => {
  const { data } = await axios.post<any>(
    `${BASE_URL}${resendCompaniesInvite}`,
    dataTosend
  )
  return data
}

export const ChangePassword = async (
  userId: number,
  dataTosend: UserChangePassword
) => {
  const { data } = await axios.put<any>(
    `${BASE_URL}${changePassword(userId)}`,
    dataTosend
  )
  return data
}

export const GetUser = async (userId: number) => {
  const { data } = await axios.get<{ data: UserInformation }>(
    `${BASE_URL}${getUser(userId)}`
  )
  return data
}

export const UpdateUser = async (
  userId: number,
  dataToSend: { user: Partial<UserInformation> }
) => {
  const { data } = await axios.put<{ data: UserInformation }>(
    `${BASE_URL}${updateUser(userId)}`,
    dataToSend
  )
  return data.data
}

export const SendNotificationToken = async (userId: number, token: string) => {
  const { data } = await axios.post<{ data: string }>(
    `${BASE_URL}${sendNotificationToken(userId)}`,
    { type: 'firebase', token }
  )
  return data
}

export const GetUserNotifications = async (userId: number) => {
  const { data } = await axios.get<{ data: Array<NotificationData> }>(
    `${BASE_URL}${userNotifications(userId)}`
  )
  return data.data
}

export const ViewedUserNotification = async (
  userId: number,
  notifcationId: number
) => {
  const { data } = await axios.put<{ data: UserInformation }>(
    `${BASE_URL}${viewedUserNotification(userId, notifcationId)}`
  )
  return data.data
}

export const AddPaymentMethod = async (dataTosend: DatatoSend) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post<any>(
    `${BASE_URL}${addPaymentMethod(companyId)}`,
    dataTosend
  )
  return data
}

export const RechargeCreditCard = async (dataTosend: { amount: number }) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post<any>(
    `${BASE_URL}${rechargeCreditCard(companyId)}`,
    dataTosend
  )
  return data
}

export const RechargeAchWire = async (dataTosend: {
  deposit_id: string
  amount: string
  file: string
  origin: string
}) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post<any>(
    `${BASE_URL}${rechargeWireTransfer(companyId)}`,
    dataTosend
  )
  return data
}

export const ActivePaymentMethod = async (dataTosend: {
  default: string
  cardId: number
}) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.put<any>(
    `${BASE_URL}${activePaymentMethod(companyId, dataTosend.cardId)}`,
    { default: Boolean(dataTosend.default) }
  )
  return data
}

export const UnRegisterCard = async (dataTosend: { cardId: number }) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.post<any>(
    `${BASE_URL}${unRegisterCard(companyId, dataTosend.cardId)}`
  )
  return data
}

export const GetTangoCompany = async () => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: { entries: TangoResponse } }>(
    `${BASE_URL}${tangoCompany(companyId)}`
  )
  return data.data
}

export const GetPaymentMethods = async (params?: {
  status?: 'ACTIVE' | 'DEACTIVATED'
  default?: boolean
}) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: { entries: Array<PaymentMethod> } }>(
    `${BASE_URL}${paymentMethods(companyId)}`,
    { params }
  )
  return data.data
}

export const GetTransactions = async () => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: { entries: Transaction } }>(
    `${BASE_URL}${transactions(companyId)}`
  )
  return data.data
}
export const GetFunds = async () => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: PaymentMethodResponse }>(
    `${BASE_URL}${getFunds(companyId)}`
  )
  return data.data
}
export const GetPositions = async (jobId: number) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.get<{ data: PositionProps }>(
    `${BASE_URL}${getPositions(companyId, jobId)}`
  )
  return data.data
}

export const ClearPositions = async (dataTosend: {
  positionId: string
  jobId: number
}) => {
  const { companyId } = getCompanyId()
  const { data } = await axios.delete<any>(
    `${BASE_URL}${clearPositions(
      companyId,
      dataTosend.jobId,
      dataTosend.positionId
    )}`
  )
  return data
}

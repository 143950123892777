import AnalyticsIcon from '@mui/icons-material/Analytics'
import BusinessIcon from '@mui/icons-material/Business'
import DisplaySettings from '@mui/icons-material/DisplaySettings'
import Diversity from '@mui/icons-material/Diversity3'
import Group from '@mui/icons-material/Group'
import Logout from '@mui/icons-material/Logout'
import Person from '@mui/icons-material/Person'
import Payments from '@mui/icons-material/Star'
import PaymentIcon from '@mui/icons-material/Wallet'
import WorkIcon from '@mui/icons-material/Work'
import American from 'components/cards/Amex'
import Dinerscard from 'components/cards/Diners'
import Discover from 'components/cards/Discover'
import Mastercard from 'components/cards/Master'
import Placeholder from 'components/cards/Placeholder'
import Visacard from 'components/cards/Visa'
import type { UserRole } from 'types/auth'
import { MenuItems, MenuProfile } from 'types/general'
import type { Fields } from 'types/profile'

export const ROUTES = ['bonus', 'jobs']
export const PAGE = 1
export const SIZE = 25
export const STATUS = ['active']
export const DEFAULT_MESSAGE = 'Something went wrong'

export const CURRENT_YEAR = new Date().getFullYear()
export const FIRST_DAY = new Date(CURRENT_YEAR, 0, 1)

export const PAGINATION = {
  totalPages: 0,
  totalEntries: 0,
  pageNumber: 0,
  pageSize: 0
}

export const archStaffing = {
  id: '5464',
  ownerName: 'Santos',
  ownerLastName: 'Santos',
  ownerSecondSurname: 'Santos',
  email: 'santos_lopez@beegopp.com',
  joinedDate: 'October 11, 2022 15:45 hrs',
  CompanyURL: 'https://beegopp/Archstaffin.j..',
  paidBonuses: '$7,408.72',
  jobs: '1104',
  employees: '300',
  Referrals: '895'
}

export const companyInformation = {
  id: '9462',
  companyName: 'Hire Dynamics',
  companyURL: 'https://beegopp/hiredynamics.jobs/en/',
  owner: 'Brenda Mortiz',
  phoneNumber: '432-345-343-12',
  street: 'Bridgeford',
  state: 'California',
  zipcode: '15205',
  country: 'Sacramento'
}

export const listImages = [
  {
    id: 1,
    img: '/images/login.png'
  },
  {
    id: 2,
    img: '/images/carousel.png'
  },
  {
    id: 3,
    img: '/images/science.png'
  }
]

export const getSettingsInformation = (
  mappedFields: Fields,
  atsEnable: string
) => [
  // {
  //   id: 1,
  //   title: 'Employees',
  //   subTitle: 'Data field mapping',
  //   begopp: 'Beegopp field',
  //   ats: `${atsEnable} field`,
  //   type: 'employee',
  //   fields: mappedFields.employee
  // },
  // {
  //   id: 2,
  //   title: 'Candidate',
  //   subTitle: 'Data field mapping',
  //   begopp: 'Beegopp field',
  //   ats: `${atsEnable} field`,
  //   type: 'candidate',
  //   fields: mappedFields.candidate
  // },
  {
    id: 3,
    title: 'Jobs',
    subTitle: 'Data field mapping',
    begopp: 'Beegopp field',
    ats: `${atsEnable} field`,
    type: 'job',
    fields: mappedFields.job
  }
]

export const dataFielInformation = [
  {
    id: 1,
    name: 'Active',
    status: 'Active'
  },
  {
    id: 2,
    name: 'Inactive',
    status: 'Active'
  },
  {
    id: 3,
    name: 'Active',
    status: 'Active'
  },
  {
    id: 4,
    name: 'Active',
    status: 'Active'
  }
]

export const statusFielInformation = [
  {
    id: 1,
    title: 'Status fiel mapping',
    name: 'Active',
    status: 'Active'
  },
  {
    id: 2,
    name: 'Active',
    status: 'Active'
  }
]

export const REGEX_NUMBERS = /^[0-9]*$/
export const SPECIAL_CHARACTERS = /[!@#$%^&*,.":<>='¿?;]/
export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export const getMenu = (userRole: UserRole): Array<MenuItems> => {
  return [
    {
      id: 1,
      title: 'Dashboard',
      route: 'dashboard',
      icon: AnalyticsIcon,
      submenu: null,
      show: permissions.dashboard.includes(userRole)
    },
    {
      id: 2,
      title: 'Wallet',
      route: 'wallet',
      icon: PaymentIcon,
      submenu: null,
      show: permissions.wallet.includes(userRole)
    },
    {
      id: 7,
      title: 'Companies',
      route: 'companies',
      icon: BusinessIcon,
      submenu: null,
      show: permissions.companies.includes(userRole)
    },
    {
      id: 3,
      title: 'Jobs',
      route: 'jobs',
      icon: WorkIcon,
      show: permissions.jobs.section.includes(userRole),
      submenu: [
        {
          id: 1,
          title: 'Board configuration',
          route: 'boardconfig',
          show: permissions.jobs.boardconfig.includes(userRole)
        },
        {
          id: 2,
          title: 'Job board',
          route: 'jobboard',
          show: permissions.jobs.jobboard.includes(userRole)
        },
        {
          id: 3,
          title: 'Import',
          route: 'import',
          show: permissions.jobs.import.includes(userRole)
        }
      ]
    },
    {
      id: 4,
      title: 'Bonus',
      route: 'bonus',
      icon: Payments,
      show: permissions.bonus.list.includes(userRole),
      submenu: [
        {
          id: 1,
          title: 'Templates',
          route: 'templates',
          show: permissions.bonus.templates.includes(userRole)
        },
        {
          id: 2,
          title: 'Payments',
          route: 'payments',
          show: permissions.bonus.payment.includes(userRole)
        }
      ]
    },
    {
      id: 5,
      title: 'Referrals',
      route: 'referrals',
      icon: Diversity,
      submenu: null,
      show: permissions.referrals.includes(userRole)
    },
    {
      id: 6,
      title: 'Employees',
      route: 'employees',
      icon: Group,
      submenu: null,
      show: permissions.employees.includes(userRole)
    }
  ]
}

export const FOOTER_ITEMS = [
  {
    id: 1,
    label: 'Privacy Policy',
    route: '',
    showDivider: true
  },
  {
    id: 2,
    label: 'Data Settings',
    route: '',
    showDivider: true
  },
  {
    id: 3,
    label: 'Terms and Conditions',
    route: '',
    showDivider: false
  }
]

export const getProfileMenu = (userRole: UserRole): Array<MenuProfile> => {
  return [
    {
      id: 1,
      title: 'View profile',
      route: 'personal',
      icon: Person,
      show: profilePermissions.personal.includes(userRole)
    },
    {
      id: 2,
      title: 'My company',
      route: 'company',
      icon: BusinessIcon,
      show: profilePermissions.company.includes(userRole)
    },
    {
      id: 3,
      title: 'Integrations',
      route: 'integrations',
      icon: DisplaySettings,
      show: profilePermissions.integrations.includes(userRole)
    },
    {
      id: 4,
      title: 'Logout',
      route: 'logout',
      icon: Logout,
      show: true
    }
  ]
}

export const ENTITIES_TO_REMOVE = [
  'password',
  'reset_token_at',
  'firebase_token',
  'expo_token',
  'reset_token',
  'last_login',
  'password_hash',
  'email_validation_at'
]

export const profilePermissions = {
  company: ['company_owner'],
  personal: ['company_owner', 'market_manager', 'rewards_manager', 'employee'],
  integrations: ['company_owner', 'market_manager']
}

export const routePermissions = {
  super_admin: ['dashboard', 'companies', 'notifications', 'help'],
  company_owner: [
    'dashboard',
    'wallet',
    'jobs',
    'import',
    'jobboard',
    'boardconfig',
    'bonus',
    'templates',
    'payments',
    'referrals',
    'employees',
    'profile',
    'personal',
    'company',
    'integrations',
    'help',
    'notifications'
  ],
  market_manager: [
    'wallet',
    'jobs',
    'bonus',
    'referrals',
    'employees',
    'profile',
    'personal',
    'integrations',
    'jobboard',
    'boardconfig',
    'import',
    'templates',
    'payments',
    'notifications',
    'help'
  ],
  rewards_manager: [
    'wallet',
    'jobs',
    'jobboard',
    'boardconfig',
    'bonus',
    'payments',
    'referrals',
    'employees',
    'profile',
    'personal',
    'notifications',
    'help'
  ],
  employee: [
    'jobs',
    'jobboard',
    'referrals',
    'profile',
    'personal',
    'wallet',
    'dashboard',
    'notifications',
    'help'
  ]
}

export const permissions = {
  dashboard: ['super_admin', 'employee', 'rewards_manager', 'company_owner'],
  wallet: ['company_owner', 'market_manager', 'rewards_manager', 'employee'],
  companies: ['super_admin'],
  jobs: {
    section: ['company_owner', 'market_manager', 'rewards_manager', 'employee'],
    import: ['company_owner', 'market_manager'],
    jobboard: [
      'company_owner',
      'market_manager',
      'rewards_manager',
      'employee'
    ],
    boardconfig: ['company_owner', 'market_manager', 'rewards_manager']
  },
  bonus: {
    list: ['company_owner', 'market_manager', 'rewards_manager'],
    templates: ['company_owner', 'market_manager'],
    payment: ['company_owner', 'market_manager', 'rewards_manager']
  },
  referrals: ['company_owner', 'market_manager', 'rewards_manager', 'employee'],
  employees: ['company_owner', 'market_manager', 'rewards_manager']
}

export const PREVIEW_TYPES = {
  gif: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  webp: 'image',
  pdf: 'pdf',
  default: 'default'
}

export const MIME_TYPES = {
  gif: 'image/*',
  jpeg: 'image/*',
  jpg: 'image/*',
  png: 'image/*',
  bmp: 'image/*',
  webp: 'image/*',
  pdf: 'application/pdf',
  csv: 'text/csv'
}

export const CARDS: any = {
  mastercard: Mastercard,
  visa: Visacard,
  visa_electron: Visacard,
  diners_cb: Dinerscard,
  diners_int: Dinerscard,
  discover: Discover,
  amex: American,
  default: Placeholder
}

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import TextField from 'components/TextField'
import Paper from 'components/paper'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import useConnect from '../hooks/useConnect'

export default function Connet() {
  const {
    selectedIntegration,
    validationSchema,
    initialValues,
    showField,
    onSubmit,
    onShowField,
    onCloseSidebar
  } = useConnect()

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {() => (
        <Stack
          component={Form}
          padding={2}
          height={'calc(100vh - 130px)'}
          justifyContent="space-between"
          sx={{
            overflow: 'auto',
            ...StylesScrollBar
          }}
        >
          <Box component={Paper} marginY={2} paddingX={3}>
            <Typography variant="h3" marginBottom={2}>
              Credentials
            </Typography>
            <Typography variant="h5" color={COLORS.gray2} marginBottom={2}>
              To connect {selectedIntegration?.name} we need this data
            </Typography>
            <Box paddingX={2}>
              {selectedIntegration?.fields.map(item => {
                const label = item.includes('_')
                  ? item.replace(/_/g, ' ').toLocaleUpperCase()
                  : item.toLocaleUpperCase()

                return (
                  <TextField
                    fullWidth
                    name={item}
                    label={label}
                    variant="standard"
                    sx={{ marginBottom: 2 }}
                    type={showField[item] ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          onClick={() => onShowField(item)}
                        >
                          {showField[item] ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      )
                    }}
                  />
                )
              })}
            </Box>
          </Box>
          <Stack marginTop={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ marginBottom: '10px' }}
            >
              Continue
            </LoadingButton>
            <Button variant="text" onClick={onCloseSidebar}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  )
}
